.income-salary{
  padding: 20px;
  padding-bottom: 0;
}

.err-txt{
  font-size: 14px;
  color: red;
}

.payments-footer-table td{
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.payments-footer-table td .MuiOutlinedInput-inputMarginDense {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90px;
}

.no-open-day{
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
