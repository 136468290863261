.add-bills-item .modal-popup__window {
  max-width: 900px;
}

.filter-client-wrap{
  min-width: 200px;
}

.outfit-remove-btn{
  margin-right: 10px !important;
}
