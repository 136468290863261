

.waybills-popup__inner{
  padding-bottom: 30px;
}

.waybills-popup__inner .mb15{
  margin-bottom: 0;
}

.waybills-popup__desc{
  width: 100%;
  margin-top: 8px;
  padding: 10px;
  /*height: 105px;*/
  height: 40px;
}

.waybills-popup__works{
  /*padding-bottom: 15px;*/
  padding-bottom: 5px;
  padding-top: 20px;
  /*border-bottom: 1px solid black;*/
  /*border-top: 1px solid black;*/
}

.waybills-popup__inner .modal-popup__input--time{
  margin-bottom: 0 !important;
}

.waybills-popup__work-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.waybills-popup__spare-parts{
  margin-top: 20px;
}

.waybills-popup__spare-parts-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.waybills-popup__work-name .MuiAutocomplete-root,
.waybills-popup__spare-parts-name .MuiAutocomplete-root{
  padding-top: 3px;
}
