.view-result{
    margin: 20px 0;
    display: flex;
}

.view-result__inner{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.view-result__col{
    display: flex;
    align-items: center;
}

.view-result__items-txt{
    color: #727c9a;
}

.view-result__subtitle{

}

.view-result__link{
    margin-left: 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #d0d8ee;
    cursor: pointer;
    transition: .3s;
}

.view-result__link-ico{
    margin-right: 10px;
}

.view-result__link svg path,
.view-result__link svg rect{
    fill: #d0d8ee;
}

.view-result__link:hover,
.view-result__link.active{
    color: #3143d9;
}

.view-result__link:hover svg path,
.view-result__link:hover svg rect,
.view-result__link.active svg path,
.view-result__link.active svg rect{
    fill: #3143d9;
}


/*projects card start*/
.projects-card{

}

.projects-card__content{
    max-width: 100%;
    padding-right: 20px;
}

.projects-card__i--create{
    /*height: 100%;*/
    min-height: 200px;
}

.projects-card__i--create .projects-card__i-content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.projects-card__i-create-task{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.projects-card__i-create-ico{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /*background: #2b3dd8;*/
    background: #da9142;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    color: white;
    margin-bottom: 10px;
}

.projects-card__i-create-task-txt{
    /*color: #2b3dd8;*/
    color: #da9142;
    font-weight: bold;
}

/*projects card end*/
