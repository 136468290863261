
.task-create__name{
    margin-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.task-create__name-input{
    width: 100%;
    padding: 15px 0 ;
}

.task-create__description{
    margin-bottom: 15px;
}

.task-create__description textarea{
    border: none;
    font-size: 14px;
    color: black;
    padding: 15px 0 ;
    width: 100%;
    border-bottom: 1px solid #eee;
    resize: none;
    min-height: 200px;
}

.task-create__description textarea:focus{
    outline: none;
}

.task-create__row{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.task-create__executor{

}

.task-create__row > span {
    margin-right: 10px;
}

.task-create__btn{
    display: flex;
    justify-content: flex-end;
}

.task-create__drafts{
    margin-right: 20px!important;
}

.task-create__save{

}

/*.task-autocomplite label + .MuiInput-formControl{*/
    /*margin-top: 0;*/
/*}*/
