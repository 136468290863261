.recording{

}



.page-section{
    background: white;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0px #0000000d;
    margin-bottom: 25px;
}

.page-section__name{
    padding: 15px;
    font-size: 18px;
    color: #7b85a1;
    font-weight: 600;
}

