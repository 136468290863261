.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiInputBase-input {
  padding: 9.5px 4px;
}

.modal-field{
  width: 100%;
  margin-top: 27px !important;
  margin-bottom: 2px !important;
}

.client-popup-radio{
  padding-top: 20px;
}
