
.project-page__top-nav {
  /*padding: 20px;*/
}



/* PROJECT */

.project{

}

.project__inner{

}

.project__left{

}

.project__back{

}

.project__status-block{
  display: flex;
  justify-content: space-between;
}

.project__status-name{

}

.project__status-title{

}

.project__status-progress{
  color: #fab70d;
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
}

.project__create{

}

.project__status-name{

}

.project__status-desc{
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 30px;
}

.project__status-desc img{
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.project__date{

}

.project__status-name{
  margin-bottom: 5px;
}


.project__sub-tasks{

}

.sub-tasks__top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sub-tasks__title{
  font-weight: bold;
  font-size: 18px;
}

.sub-tasks__create-btn{
  text-transform: unset !important;
}

.sub-tasks__items{

}

.sub-tasks__item{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sub-tasks__input{

}

.sub-tasks__input-checkbox{
  padding: 0 !important;
}

.sub-tasks__name{
  font-weight: 500;
  margin-left: 5px;
}

.sub-tasks__status{
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
}

.sub-tasks__status--process{
  color: #fa783f;
}

.sub-tasks__status--waits{
  color: #fbc84a;
}

.sub-tasks__status--approved{
  color: #5fc293;
}

/* PROJECT END */



/* PROJECT DETAIL */

.project-detail__title{
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.project-detail__desc{
  font-size: 14px;
}

.project-detail__desc-name{
  color: #585961;
  margin-bottom: 10px;
  font-weight: 500;
}

.project-detail__desc-name .site-link{
  margin-left: 10px;
}

.project-detail__desc-txt{

}

.project-detail__desc-txt *{
  font-weight: 700;
}

.project-detail__desc-txt p{
  margin-bottom: 30px;
}

.project-detail__desc-txt ul{
  padding: 0;
  list-style: none;
  margin: 10px 0 0;
}

.project-detail__desc-txt li{
  margin-bottom: 10px;
}

.project-detail__img{
  margin-bottom: 30px;
}

.project-detail__img-items{
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*justify-content: space-between;*/
  width: 100%;
}

.project-detail__img-i{
  display: flex;
  align-items: center;
  /*width: 50%;*/
}

.project-detail__img-i-w{
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f3fa;
}

.project-detail__img-i-w img{
  max-width: 100%;
  max-height: 100%;
}

.project-detail__img-desc{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.project-detail__img-desc span{
  margin-top: 5px;
  color: #79829f;
}

.project-detail__show-link{
  color: #4a59dd;
  font-weight: 700;
  margin-top: 20px;
}

.project-detail__info{
  margin-bottom: 40px;
}

.project-detail__categories{
  color: #4a59dd;
  font-weight: 700;
}

.project-detail__deadline{
  font-weight: bold;
}

.project-detail__assigned{
  display: flex;
  margin-bottom: 40px;
}

.project-detail__assigned-i{
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 15px;
}

.project-detail__assigned-i img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.project-detail__notes{

}

.project-detail__notes div{
  font-weight: bold;
  margin-bottom: 10px;
}

/* END PROJECT DETAIL */
