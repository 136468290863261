
.login-page{
  height: 100vh;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

.forgot-block{
  margin-top: 20px;
}

.login-page__error{
  color: #f44336;
}

.fixed-lang{
  position: fixed;
  right: 15px;
  top: 0px;
}
