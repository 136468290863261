

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'), url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.ttf')  format('truetype'), url('./assets/fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Light/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Montserrat-Light/Montserrat-Light.woff') format('woff'), url('./assets/fonts/Montserrat-Light/Montserrat-Light.ttf')  format('truetype'), url('./assets/fonts/Montserrat-Light/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),  url('./assets/fonts/Montserrat-Medium/Montserrat-Medium.woff') format('woff'), url('./assets/fonts/Montserrat-Medium/Montserrat-Medium.ttf')  format('truetype'), url('./assets/fonts/Montserrat-Medium/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),  url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'), url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.ttf')  format('truetype'), url('./assets/fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),  url('./assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff') format('woff'), url('./assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.ttf')  format('truetype'), url('./assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

*{
  box-sizing: border-box;
  font-family: 'Montserrat';
  font-weight: 400;
}

.site-wrap{
  display: flex;
}

.site-wrap.menu{
  overflow: hidden;
  height: 100vh;
}

.main-wrap{
  width: 100%;
}

body{
  background: #f9f9fb;
}

a{
  text-decoration: none;
  color: black;
}

.MuiFormGroup-root.radio-group{
  flex-direction: row;
  align-items: center;
}

.radio-group .MuiTypography-body1 {
  font-size: 15px;
}

.no-records{
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.error-textarea{
  border: 1px solid red;
}

.task-create__description textarea.error-textarea{
  border-bottom: 2px solid #f44336 !important;
}

.error-txt{
  color: #f44336;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}

.MuiInputBase-input {
  font-size: 14px !important;
  height: 19px !important;
}

.MuiAutocomplete-popper * {
  font-size: 14px !important;
}

.top-nav{
  padding: 10px 30px 20px;
  background: white;
  box-shadow: 0 0 20px #0000001a;
  margin-top: 20px;
}

.top-nav__head{
  display: flex;
  justify-content: space-between;
  position: relative;
}

.top-nav__head:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #eef0f7;
  z-index: 1;
}

.top-nav__left{
  display: flex;
  align-items: center;
}

.top-nav__tabs{
  display: flex;
}

.top-nav__tabs-item{
  position: relative;
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #7b85a1;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s;
}

.top-nav__tabs-item:hover{
  color: #da9142;
}

.top-nav__tabs-item.active{
  color: #da9142;
}

.top-nav__tabs-item:after{
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background: transparent;
  z-index: 2;
  transition: 0.3s;
}

.top-nav__tabs-item:hover:after,
.top-nav__tabs-item.active:after{
  background: #da9142;
}

.top-nav__right{
  display: flex;
  align-items: center;

  margin-bottom: 10px;
}

.top-nav__team{
  display: flex;
  align-items: center;
}

.top-nav__title{
  margin-right: 15px;
  font-weight: 500;
  color: #7b85a1;
}

.top-nav__photo{
  width: 25px;
  display: flex;
}

.top-nav__photo img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.top-nav__manage{
  margin-left: 15px;
  font-weight: 600;
  color: #7b85a1;
}

.top-nav__bottom{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}


.top-nav__tabs-content{
  display: flex;
  align-items: center;
}

.top-nav__tabs-content-item{
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.top-nav__tabs-content-name{
  margin-right: 10px;
}

.top-nav__drafts{
  margin-right: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #7b85a1;
}

.top-nav__create{

}




.site-back{
  font-size: 14px;
  color: #585961;
  font-weight: 500;
}

.site-block{
  margin-top: 30px;
  background: white;
  box-shadow: 0 0 24px 5px #00000009;
  padding: 30px;
  border-radius: 2px;
}

.site-link{
  font-weight: 700;
  color: #79829f;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}


.page-title{
  margin-top: -30px;
  margin-bottom: 30px;
}


.site-table-row{
  height: 60px;
}

.filter-head{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px 0px #0000000d;
  background: #fff;
  align-items: center;
}

.filter-head__left{
  display: flex;
  align-items: center;
}

.site-filter{
  display: flex;
  align-items: center;
}

.site-filter > .MuiTextField-root:first-child{
  margin-right: 20px;
}

.site-filter > span{
  margin-right: 15px;
}

.site-filter__btn{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  background: #eee;
  padding: 15px;
  border-radius: 4px;

  align-items: center;
  box-shadow: 2px 2px 6px 0px #0000000d;
  background: #fff;
}

.site-filter__btn button:first-child{
  /*margin-right: 20px;*/
}

.site-filter__btn form,
.site-filter__btn button{
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.recording__add-btn{
  margin-left: 20px !important;
}


.pagination-table-footer{
  display: flex;
}

.loading-table{
  position: relative;
  min-height: 110px;
}

.waybills-popup__inner .loading-table{
  min-height: auto;
}

.modal-popup__input--time.modal-field .MuiOutlinedInput-input {
  padding: 10px 10px;
}

.site-filter .MuiFormControl-root{
  min-width: 120px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-table:before{
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border-top: 2px solid #da9142;
  border-right: 2px solid #da9142;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading-table:after{
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.5;
}

@media (max-width: 1320px) {
  .page-title{
    margin-top: 0;
  }
}

@media (max-width: 1610px) {
  /*.filter-head .MuiButton-root{*/
    /*font-size: 11px !important;*/
    /*padding: 3px 8px !important;*/
  /*}*/

  .page-section .MuiButton-root{
    font-size: 11px !important;
    padding: 3px 8px !important;
  }

  table .MuiButton-root{
    margin-bottom: 10px;
  }

  .site-filter__btn {
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .MuiTableCell-sizeSmall {
    padding: 6px 8px 6px 8px !important;
    /*padding: 0px 4px 0px 5px!important;*/
  }

  .MuiTableCell-root {
    font-size: 13px !important;
  }

  .MuiContainer-root {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .site-table-row {
    height: 50px;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 8px) scale(1);
    font-size: 14px;
  }

  .modal-popup__window {
    margin-top: 25px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 5px 4px;
  }

  .modal-popup__input--autocomplete {
    margin-top: 15px !important;
  }

  .modal-field {
    margin-top: 15px !important;
  }

  .PrivateSwitchBase-root-5 {
    padding: 5px !important;
  }

  .MuiFormLabel-root{
    font-size: 14px !important;
  }

  .radio-group .MuiTypography-body1 {
    font-size: 14px !important;
  }

  .radio-group .MuiSvgIcon-root {
    width: 18px !important;
    height: 18px !important;
  }

  .waybills-popup__inner .mb15 {
    margin-bottom: 5px !important;
  }

  .waybills-popup__works {
    padding-bottom: 0px !important;
    padding-top: 8px !important;
  }

  .settings {
    padding: 5px !important;
  }

  /*.dashboard-cards__inner>div {*/
    /*margin-bottom: 10px;*/
    /*padding: 0 8px;*/
  /*}*/

  /*.MuiTableRow-root{*/
    /*height: 40px !important;*/
  /*}*/
  /*.MuiTableRow-root .MuiCollapse-container.MuiCollapse-entered{*/
    /*height: 40px;*/
  /*}*/
}

@media (max-width: 1050px) {
  .MuiTabs-flexContainer{
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .filter-head__left{
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }

  .filter-head{
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .filter-head__left{
    flex-wrap: wrap;
    width: 100%;
    /*justify-content: space-between;*/
  }

  .filter-client-wrap{
    margin-left: 0;
    /*margin-top: 10px;*/
  }

  .site-filter__btn form{
    margin-left: 0;
  }

  .filter-head .recording__add-btn{
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .site-filter{
    flex-direction: column;
    align-items: flex-start;
  }

  .top-nav{
    overflow: scroll;
    padding: 10px 15px 20px;
  }

  .top-nav__head{
    padding-right: 20px;
    min-width: max-content;
  }

  .top-nav__tabs-item{
    height: 35px;
    font-size: 12px;
  }

  .top-nav__right{
    margin-bottom: 5px;
  }

  .top-nav__drafts{
    font-size: 12px;
    margin-right: 20px;
  }

  .top-nav__create button{
    padding: 5px 9px;
    font-size: 10px;
  }
}

@media (max-width: 500px) {

  .filter-head__left{
    justify-content: space-between;
  }

  .recording__add-btn{
    margin: 0 !important;
  }

  .filter-client-select,
  .filter-client-wrap{
    width: 100%;
    margin-bottom: 10px;
  }

  .site-filter{
    width: 100%;
  }

  .site-filter__btn{
    align-items: flex-start;
  }

  .site-filter .MuiFormControl-root.MuiTextField-root{
    width: 100%;
    margin-bottom: 10px;
  }

  .site-filter__btn button {
    margin-left: 9px;
    font-size: 9px;
  }


}
