.product-waybills-popup__work-head .MuiAutocomplete-endAdornment {
  display: flex;
}

/*.product-waybills-popup__work-head > div{*/
/*padding-left: 0;*/
/*padding-right: 0;*/
/*}*/

.waybills-popup__works--btn {
  display: flex;
  justify-content: flex-end;
}


.product-waybills-popup__work-head .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input:first-child {
  padding: 6px 0;
}

.create-product-block {
  margin-top: -10px;
}

.create-product-block .MuiAutocomplete-endAdornment {
  display: flex;
}

.create-product-block .MuiFormControl-marginNormal {
  margin-top: 0px;
}


.create-product-block .modal-popup__header {
  margin-bottom: 10px;
}

.create-product-block .waybills-popup__works {
  margin-top: auto;
}

.create-product-block {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: white;
  border-radius: 10px;
  align-content: baseline;
}

.add-product-popup-inner {
  position: relative;
}

.filter-client-select.read{
  pointer-events: none;
}

.add-list-product-btn{
  width: 20px;
  display: block;
  min-width: 34px !important;
}

.add-list-product-btn .MuiButton-label{
  font-size: 28px;
  line-height: 15px;
  width: 20px;
  transform: rotate(45deg);
}

.product-waybills-close-btn{
  margin-right: auto !important;
  margin-left: 0 !important;
}


