.aside{
  min-width: 280px;
  min-height: 100vh;
  box-shadow: 0 0 24px 5px #00000009;
  display: flex;
  background: white;
  max-width: 200px;
}

.aside__inner{
  /*padding: 30px 10px;*/
  padding: 30px 10px 30px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.aside__top{
  color: #252732;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.aside__top img{
  max-width: 130px;
}

.aside__top span{
  color: #3748da;
  font-weight: 600;
}

.aside__user{
  display: flex;
  align-items: center;
  /*flex-direction: column;*/
}

.aside__user-img{
  width: 40px;
  height: 40px;
  margin-right: 15px;
  /*margin-bottom: 10px;*/
}

.aside__user-img img{
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.aside__user-right{

}

.aside__user-name{
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.aside__user-position{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.aside__time{
  margin-top: 10px;
}

.aside__time-title{
  color: #7b85a1;
  font-size: 18px;
  margin-bottom: 3px;
  font-weight: 500;
}

.aside__time-block{

}

.aside__time-current{
  font-size: 45px;
  color: #7b85a1;
  font-weight: 600;
}

.aside__time-btn{

}

.aside__nav{
  margin-top: 50px;
}

.aside__nav-items{
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

.aside__nav-item{
  margin-bottom: 20px;
  cursor: pointer;
}


.aside__nav-link{
  color: #7b85a1;
  transition: 0.3s;
  display: block;
  position: relative;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}

.aside__nav-link:after{
  content: '';
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  margin-right: -10px;
  transition: 0.3s;
}

.aside__nav-link:hover:after{
  background: #3748da;
  background: #da9142;
}

.aside__nav-link:hover{
  color: #3748da;
  color: #da9142;
}

.aside__nav-link.active{
  color: #3748da;
  color: #da9142;
}

.aside__nav-link.active:after{
  background: #3748da;
  background: #da9142;
}

.aside__copyright{
  font-size: 14px;
  margin-top: auto;
  color: #7b85a1;
}

.logo-name{
  background: #404040;
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
}
.logo-name .logo{
  color: #d8d48b;
  font-size: 18px;
}

.logo-name .logo-sub{
  color: #da9142;
  font-size: 18px;
}

@media (max-width: 1600px){
  .aside {
    min-width: 215px;
  }

  .aside__inner{
    padding: 30px 10px 30px 15px;
  }
}


@media (max-width: 1290px) {
  .aside {
    min-width: 160px;
  }

  .aside__user{
    flex-direction: column;
    align-items: flex-start;
  }

  .aside__user-position{

  }

  /*.aside__inner {*/
    /*padding: 30px 10px;*/
  /*}*/

  .aside__nav-link{
    /*font-size: 20px;*/
  }

  .aside__user-name {
    /*font-size: 15px;*/
  }

  .aside__copyright {
    font-size: 11px;
  }

  .aside__user-position {
    /*font-size: 14px;*/
  }

  .aside__nav-link:after {
    margin-right: -15px;
  }

  .aside__user-img {
    /*width: 50px;*/
    margin-bottom: 5px;
    margin-right: 0;
  }

  .aside__nav-link{
   font-size: 16px;
  }

}



.menu-close-mob{
  display: none;
}


@media (min-width: 1050px) and (max-width: 1600px){
  .aside {
    min-width: 160px;
  }

  .logo-name .logo{
    font-size: 16px;
  }

  .logo-name .logo-sub{
    font-size: 16px;
  }


  .aside__inner {
    padding: 30px 15px 30px 15px;
  }

  .aside__nav-link {
    font-size: 12px;
  }

  .aside__nav-item {
    /*margin-bottom: 10px;*/
    margin-bottom: 6px;
  }
  .aside__nav {
    margin-top: 20px;
  }

  .dashboard-cards__inner{
    margin-right: -9px;
  }
}

@media (max-width: 1290px) {
  .logo-name .logo-sub{
    font-size: 14px;
  }
}

@media (max-width: 1050px) {
  .aside{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-110%);
    background: white;
    z-index: 2;
    transition: 0.3s;
    min-width: 280px;
  }

  .aside.open{
    transform: translateX(0);
  }

  .aside.open:before{
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #00000045;
    z-index: 3;
  }

  .menu-close-mob{
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 35px;
    transform: rotate(45deg);
    display: block;
  }

  .aside.open .aside__inner{
    overflow: scroll;
    overflow-x: hidden;
    height: 100vh;
  }

  .aside__inner {
    /*padding: 50px 25px;*/
    padding: 30px 10px 30px 15px;
    position: relative;
    z-index: 10;
    background: white;
  }

  .aside__user-img img{
    width: 40px;
  }

  .header__search{
    order: 10;
    width: 100%;
    margin-top: -8px !important;
  }

  .header__search .MuiFormControl-marginDense{
    width: 100%;
  }

  .header .MuiGrid-container{
    flex-wrap: wrap;
    padding-top: 5px;
  }

  .page-title {
    margin-top: 10px;
    margin-bottom: 15px;
  }

}

@media (max-width: 500px) {
  .header__notification,
  .header__settings {
    width: 20px;
    height: 20px;
  }

  .date-block .aside__time-current,
  .time-block .aside__time-current {
    font-size: 15px;
    display: none;
  }
}
