.sell-btn{
  margin-left: auto !important;
  display: block !important;
  margin-top: 32px !important;
}

.product-popup-row{
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid black !important;
}

.product-sell-remove{
  margin-left: auto;
  margin-top: 5px;
}

.sell-product-popup{
  position: relative;
}

.sell-product-success-popup .modal-popup__footer{
  margin-top: 40px;
}

.sell-product-success-popup .modal-popup__footer .modal-popup__btn{
  justify-content: center;
}

.sell-product-success-popup .modal-popup__header{
  padding-top: 0;
  text-align: center;
}

.sell-product-success-popup{
  position: absolute;
  padding: 0 30px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  justify-content: center;
}
