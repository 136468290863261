

.dashboard{

}

.dashboard__time{
  display: flex;

}

.dashboard__current-time,
.dashboard__curent-data{
  background: white;
  box-shadow: 2px 2px 6px #0000001f;
  height: 150px;
  /*width: 320px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 40px;
  color: #7b85a1;
  border-radius: 3px;
  margin-right: 30px;
}

.dashboard__current-time span,
.dashboard__curent-data span{
  font-size: 15px;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}









.dashboard-cards{
  margin-top: 20px;
}

.dashboard-cards__inner{

}

.dashboard-cards__inner > div{
  margin-bottom: 20px;
}

.dashboard-cards__inner > div.col-md-2 {
  padding: 0 8px;
}

.dashboard-cards__item{
  padding: 25px 20px;
  background: white;
  border-radius: 8px;
  transition: 0.3s;
  box-shadow: 2px 2px 6px #0000000a;
  height: 100%;
  display: flex;
  flex-direction: column;
}



/*.dashboard-cards__item:hover{*/
  /*transform: translateY(-2px);*/
  /*box-shadow: 2px 2px 6px #0000001f;*/
/*}*/

.dashboard-cards__title{
  color: #58d2a3;
  font-weight: 500;
}

.dashboard-cards__caption{
  font-size: 14px;
  color: #bfbfc1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dashboard-cards__caption .MuiRadio-root{
  padding: 3px 8px;
}

.radio-group-dashboard{
  margin-top: 4px;
}

.dashboard-cards__money{
  font-size: 25px;
  font-weight: 500;
  margin-top: auto;
  color: #7b85a1;
}

.dashboard-cards__count{
  font-size: 14px;
  color: #bfbfc1;
  margin-top: 20px;
}

.dashboard-cards__count b{
  color: black;
  margin-left: 5px;
  font-size: 16px;
}

.dashboard-cards__item--orange .dashboard-cards__title{
  color: #000000;
}

.dashboard-cards__item--orange{
  background: #cea74f;
}

.dashboard-cards__item--green .MuiFormControlLabel-label{
  color: white;
}

.dashboard-cards__item--green .MuiSvgIcon-fontSizeSmall path{
  fill: #21232f;
}

.dashboard-cards__item--green{
  background: #7be6bd;
}

.dashboard-cards__item--green .dashboard-cards__title{
  color: #000000;
}

.dashboard-cards__item--green .dashboard-cards__money{
  color: #ffffff;
}

.dashboard-cards__item--orange .dashboard-cards__caption,
.dashboard-cards__item--orange .dashboard-cards__money,
.dashboard-cards__item--orange .dashboard-cards__count,
.dashboard-cards__item--orange .dashboard-cards__count b{
  color: white;
}

.dashboard__actual-task{
  margin: 20px 0;
}

.dashboard__actual-task-title{
  margin-bottom: 30px;
}

.task-snack{

}

.task-snack__create-title {
  padding-top: 10px;
  font-weight: 500;
}

.task-snack__create-title span:first-child{
  color: #da9142;
  font-size: 16px;
}
.task-snack__create-title span:last-child{
  color: #d8d48b;
  font-size: 18px;
}

.task-snack__create-txt{
  margin-top: 10px;
  font-weight: 500;
  padding-bottom: 15px;
}

.task-snack__create-txt span:first-child{
  color: #da9142;
}
.task-snack__create-txt span:last-child{
  color: white;
}

.dashboard-records-block .filter-head{
  margin-bottom: 0;
}

@media (max-width: 1290px) {
  .dashboard-cards__money {
    font-size: 22px;
    font-weight: 500;
    color: #7b85a1;
  }

  .dashboard-cards__inner{
    margin-right: 0 !important;
  }
}
