
.products-table{
  padding: 20px;
  background: white;
}

.products-table-row{
  height: 50px;
}

.products-table-head th{
  font-weight: bold;
  height: 40px;
}

.products button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeSmall{
  border-radius: 5px;
}
