

.settings{
  background: white;
  padding: 30px;
}

.settings-main{
  max-width: 600px;
}

.settings-main__photo{
  margin-bottom: 20px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-main__photo img {
  width: 100px;
  border-radius: 50%;
  display: block;
  margin-bottom: 10px;
}

/*.settings-main__photo .wrapper-progress-btn{*/
  /*display: block;*/
/*}*/

.settings-main__row{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.settings-main__row:last-child{
  border-bottom: 0;
}

.settings-main__row button:nth-child(2),
.settings-main__row span:nth-child(2){
  margin-left: auto !important;
}

.settings-main__row span:nth-child(2) .MuiFormControl-root{
  width: 220px;
}

.settings-main__row span:nth-child(2) .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(10px, 10px) scale(1);
  pointer-events: none;
}

.settings-main__row span:nth-child(2) .MuiInputBase-input {
  font-size: 14px;
}

.settings-main__row span:nth-child(2) .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

.settings-main__row span:nth-child(2) .MuiOutlinedInput-input {
  padding: 7.5px 14px;
}

.settings-main__btn{
  font-size: 12px !important;
  margin-left: auto !important;
}

.settings-main__btn-right{
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
}

.settings-main-label{

}

.wrapper-progress-btn{
  position: relative;
  display: inline-block;
}

.progress-btn{
  background-color: #4caf50;
}

.progress-btn-prog {
  color: #4caf50;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.settings-title-tab{
  font-weight: 600;
  font-size: 18px;
}

.settings .site-filter__btn{
  margin: 0;
  background: #f8f8fa;
}


.add-master .modal-popup__window{
  max-width: 500px;
}


@media (max-width: 768px) {
  .settings-main__row{
    flex-wrap: wrap;
  }

  .settings-main-label{
    width: 100%;
  }

  .settings-main__row button:nth-child(2), .settings-main__row span:nth-child(2) {
    margin-left: 0 !important;
  }

  .settings{
    padding: 0;
  }

  .settings .MuiTabs-scroller button{
    font-size: 11px;
  }
}

@media (max-width: 420px) {
  .settings-main__row span:nth-child(2) .MuiFormControl-root {
    width: 180px;
  }

  .settings-main__btn{
    border: 1px solid #f5005761 !important;
  }
}

@media (max-width: 370px) {
  .settings-main__row{
    flex-direction: column;
    align-items: flex-start;
  }

  .settings-main__row button:nth-child(2), .settings-main__row span:nth-child(2),
  .settings-main__row span:nth-child(2) .MuiFormControl-root {
    width: 100%;
  }

  .settings-main__btn{
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
}

