.reports-table th:nth-child(1), .rep-1{
  width: 5%;
}
.reports-table th:nth-child(2), .rep-2{
  width: 15%;
}
.reports-table th:nth-child(3), .rep-3{
  width: 15%;
}
.reports-table th:nth-child(4), .rep-4{
  width: 15%;
}
.reports-table th:nth-child(5), .rep-5{
  width: 15%;
}
.reports-table th:nth-child(6), .rep-6{
  width: 15%;
}
.reports-table th:nth-child(7), .rep-7{
  width: 10%;
}
.reports-table th:nth-child(8), .rep-8{
  width: 10%;
}
.reports-table th:nth-child(9), .rep-9{
  width: 10%;
}

.name-rep{
  font-size: 20px;
  padding-left: 15px;
  padding-top: 13px;
  display: inline-block;
}

.reports-kassa-row{
  border-top: 2px solid black;
}
