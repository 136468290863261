.projects-card__i{
    margin-bottom: 30px;
    background: white;
    height: calc(100% - 30px);
}

.projects-card__i-content{
    padding: 20px;
    box-shadow: 0 0 20px -10px #ccc9d1;
    height: 100%;
    display: flex;
    transition: .3s;
    flex-direction: column;
}

.projects-card__i-content:hover{
    box-shadow: 0 0 20px 2px #ccc9d1;
}

.projects-card__i-status{
    font-weight: bold;
    font-size: 14px;
}

.projects-card__i-status.yellow{
    color: #fac234;
}

.projects-card__i-status.green{
    color: #49ba83;
}

.projects-card__i-name{
    font-weight: bold;
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
    padding-right: 50px;
    transition: .3s;
    line-height: 24px;
    cursor: pointer;
}

.projects-card__i-name:hover{
    color: #2b3dd8;
}

.projects-card__i-row{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: auto;
}

.projects-card__i-row-ico{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.projects-card__i-row-i{
    color: #bec3d1;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.projects-card__i-row-i svg path{
    fill: #bec3d1;
}

.projects-card__i-row-photo {
    display: flex;
    margin-left: auto;
}

.projects-card__i-row-photo-i{
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /*margin-right: -5px;*/
    z-index: 4;
}
.projects-card__i-row-photo-i:nth-child(2){
    z-index: 3;
}
.projects-card__i-row-photo-i:nth-child(3){
    z-index: 2;
}
.projects-card__i-row-photo-i:nth-child(4){
    z-index: 1;
}

.projects-card__i-row-photo-i--txt{
    color: #959db6;
    background-color: #d4dbf0;
    font-size: 12px;
    font-weight: bold;
}

.projects-card__i-row-photo-i img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projects-card__i-subtask{
    position: relative;
    margin-top: auto;
}

.projects-card__i-subtask-link{
    color: #2b3dd8;
    font-weight: bold;
    cursor: pointer;
    position: relative;
}

.projects-card__i-subtask-link:after{
    content: '';
    position: relative;
    width: 9px;
    height: 9px;
    display: inline-block;
    border-top: 2px solid #2b3dd8;
    border-right: 2px solid #2b3dd8;
    transform: rotate(135deg);
    top: -3px;
    left: 7px;
    transition: .3s all;
}

/*.projects-card__i-subtask-link:hover:after,*/
.projects-card__i-subtask.active .projects-card__i-subtask-link:after{
    transform: rotate(135deg) scale(-1, -1) translate(-3px, 3px);
}

.projects-card__i-subtask-list{
    transition: .3s all;
    position: absolute;
    left: 0;
    top: 40px;
    background: #f9f9ff;
    margin: 0 -20px;
    width: calc(100% + 40px);
    box-shadow: 0 5px 20px -15px #ccc9d1;

    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}

.projects-card__i-subtask.active .projects-card__i-subtask-list{
    max-height: 1000px;
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.projects-card__i-subtask-list .projects-card__i-subtask-item{
    padding: 20px;
    border-bottom: 1px solid #f1f3fa;
    display: flex;
    flex-direction: column;
}
.projects-card__i-subtask-list .projects-card__i-row{
    margin-bottom: 0;
}

.projects-card__i-row-time{
    display: flex;
    flex-direction: column;
}

.projects-card__i-row-time-i{
    color: #b3b8c3;
    margin-bottom: 7px;
    font-weight: 500;
}

.projects-card__i-row-time-i span{
    min-width: 135px;
    display: inline-block;
    color: black;
}

.projects-card__i-description{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #7b85a1;
}

.projects-card__i-btn{
    display: flex;
    justify-content: flex-end;
}

.projects-card__i-btn-done span{
    font-weight: 500;
    font-size: 0.7rem;
}

.projects-card__i-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
