body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiButton-containedPrimary{
  background-color: #da9142 !important;
}

.MuiButton-containedSecondary {
  background-color: #8e8e8e !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: rgb(196 152 113) !important;
}

.MuiInputLabel-root.Mui-focused{
  color: rgb(196 152 113) !important;;
}

.yellow-btn{
  background-color: #fbe04d !important;
}

.yellow-btn .MuiButton-label{
  color: #404040 !important;
}



.modal-popup{
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: .3s all;
}

.modal-popup.open{
  z-index: 1000;
  visibility: visible;
  opacity: 1;
}

.modal-popup__overlay{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  overflow: auto;
}

.modal-popup__overlay-inner{
  height: 0;
}

.modal-popup__window{
  /*background: white;*/
  /*position: absolute;*/
  /*left: 50%;*/
  /*top: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*border-radius: 4px;*/
  /*padding: 0 30px;*/

  /*max-width: 700px;*/
  /*width: 100%;*/

  background: white;
  left: 0;
  top: 0;
  border-radius: 4px;
  padding: 0 30px;
  max-width: 700px;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.modal-popup__window.container{
  max-width: 1140px;
}

.modal-popup__content{
  width: 100%;
}

.modal-popup__header{
  padding-top: 30px;
}

.modal-popup__header > span{
  color: #3748da;
  color: #da9142;
  font-size: 18px;
  font-weight: 600;
}

.modal-popup__body{
  padding: 20px 0;
}

.modal-popup__footer{
  padding: 15px 0 20px;
}

.modal-popup__row{

}

.modal-popup__input{
  position: relative;
}

.modal-popup__input--select{
  display: flex !important;
  margin-top: 32px !important;
  margin-bottom: 8px !important;
}

.modal-popup__input--time{
  display: flex !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}

.modal-popup__input--autocomplete{
  display: flex !important;
  margin-top: 28px !important;
  margin-bottom: 3px !important;
}

.modal-popup__input--name{
  display: flex !important;
  margin-top: 18px !important;
  margin-bottom: 3px !important;
}

.modal-popup__input--date{
  width: 100%;
}

.modal-popup__input--date .MuiInputBase-root{
  display: flex !important;
  margin-top: 28px !important;
  margin-bottom: 3px !important;
}

.modal-popup__btn{
  display: flex;
  justify-content: flex-end;
}

.modal-popup__btn-i{
  margin-left: 20px !important;
}


.task-snack .MuiSnackbarContent-root{
  background-color: rgb(64 64 64);
}

.task-snack .MuiSnackbarContent-action{
  width: 100%;
  padding: 0;
  margin: 0;
  max-width: 300px;
}

.task-snack__create-btn{
  color: red !important;
}


.settings .Mui-selected .MuiTab-wrapper{
  color: #fbe04d;
}

.settings .MuiTabs-indicator{
  background-color: #da9142;
}

.settings .MuiAppBar-colorPrimary{
  background-color: #404040;
}

.filter-client-wrap{
  margin-left: 20px;
}

.filter-client-select .MuiAutocomplete-inputRoot {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.filter-client-select .MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
}

