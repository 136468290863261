
.clients-table{
  padding: 20px;
  background: white;
}



.clients-table-head th{
  font-weight: bold;
  height: 40px;
}

.client-edit-btn{
  margin-left: 10px !important;
}
