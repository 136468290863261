
.products-table{
  padding: 20px;
  background: white;
}

.products-table-row{
  height: 50px;
}

.products-table-head th{
  font-weight: bold;
  height: 40px;
}


