
.forgot-page{
  height: 100vh;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

.to-login-block{
  margin-top: 20px;
}
